import React, { useState, createContext } from 'react';

const UkPageContext = createContext();

export function UkPageProvider({ children }) {
	const [isUk, setIsUk] = useState(false);
	return (
		<UkPageContext.Provider
			value={{
				isUk,
				setIsUk,
			}}>
			{children}
		</UkPageContext.Provider>
	);
}

export default UkPageContext;
