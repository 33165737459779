import { useState, useEffect } from 'react';

export default () => {
	//sets the product to determine themes
	// 'workwave', 'service', 'routeManager', 'pestpac', 'realGreen'
	const [product, setProductTheme] = useState('realGreen');

	//searches url for which product to set the theme

	useEffect(() => {
		const browser = typeof window !== 'undefined';

		if (browser) {
			const {
				location: { href },
			} = window;

			const checkProduct = (text, expression) => {
				return new RegExp(expression).test(text);
			};

			if (checkProduct(href, /\broute-manager\b/)) {
				setProductTheme('routeManager');
			}
			if (checkProduct(href, /\bpestpac\b/)) {
				setProductTheme('pestpac');
			}
			if (checkProduct(href, /\bservice\b/)) {
				setProductTheme('service');
			}
			if (checkProduct(href, /\brealgreen\b/)) {
				setProductTheme('realGreen');
			}
		}
	}, []);

	return product;
};
